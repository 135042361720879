import { render, staticRenderFns } from "./404.vue?vue&type=template&id=17578f86"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorContent: require('/opt/build/repo/src/client/components/error-content/error-content.vue').default})
